import { Box, Typography } from '@mui/material'
import { useTexts } from '../../../../texts'

const styles = {
  root: {
    width: (theme) => ({
      xs: `calc(100% - ${theme.spacing(2)})`,
      sm: '100%',
    }),
    mx: 'auto',
    p: (theme) => ({
      xs: theme.spacing(2),
      md: theme.spacing(3),
      lg: theme.spacing(8, 4, 9),
      xl: theme.spacing(8, 8.75, 9),
    }),
    color: 'neutral.dark',
    textAlign: 'center',
  },
}

const EmptyState = () => {
  const texts = useTexts()
  return (
    <Box sx={styles.root}>
      <Typography>{texts.getPurchasesEmptyTextLabel()}</Typography>
    </Box>
  )
}

export default EmptyState
