import { Typography, Box } from '@mui/material'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { useTexts } from '../../../../texts'
import { CircularProgress } from '../../../components'
import articleCardStyles from '../../../components/ArticleCard/articleCard.styles'
import { PageContent } from '../../../Layout'
import { openMediaLink } from '../../../utils'
import EmptyState from './EmptyState'
import Table from './Table'
import {
  PURCHASE_TABLE_HEAD_DATA,
  PURCHASE_TABLE_ROWS_PER_PAGE_OPTIONS,
} from './constants'
import usePaginatedPurchases from './usePaginatedPurchases'

const Purchases = () => {
  const texts = useTexts()

  const {
    purchases,
    pagination,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeOrderBy,
    handleChangeOrderDirection,
  } = usePaginatedPurchases()

  const handleOpenArticle = (purchaseData) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_PURCHASES_TABLE_ARTICLE,
          articleId: purchaseData.articleId,
        },
      }),
    )

    openMediaLink(purchaseData.url)
  }

  return (
    <PageContent>
      <Typography
        component='h2'
        variant='h4'
        sx={{ mx: articleCardStyles.card.px }}
      >
        {texts.getPurchasesLabel()}
      </Typography>

      <Box className='mobile-full-bleed'>
        {purchases.isLoading ? (
          <CircularProgress />
        ) : purchases.error ? (
          <Typography>{texts.getError()}</Typography>
        ) : (
          <>
            {purchases.data.length === 0 ? (
              <EmptyState />
            ) : (
              <Table
                headData={PURCHASE_TABLE_HEAD_DATA}
                rowsPerPageOptions={PURCHASE_TABLE_ROWS_PER_PAGE_OPTIONS}
                data={purchases.data ?? []}
                totalCount={totalCount}
                page={pagination.offset}
                changePage={handleChangePage}
                rowsPerPage={pagination.limit}
                changeRowsPerPage={handleChangeRowsPerPage}
                orderBy={pagination.orderBy}
                changeOrderBy={handleChangeOrderBy}
                orderDirection={pagination.orderDirection.toLowerCase()}
                changeOrderDirection={handleChangeOrderDirection}
                onOpenArticle={handleOpenArticle}
              />
            )}
          </>
        )}
      </Box>
    </PageContent>
  )
}

export default Purchases
